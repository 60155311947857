.popoverTitle {
    text-align: center;
}

.popoverWrapper {
    position: relative;
    display: inline-block;

    .popoverContent {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: translate(0, 10px);
        background-color: var(--grey-200);
        padding: 0.5rem;
        box-shadow: 0 2px 5px 0 var(--grey-800);
        width: 100%;

        &:before {
            position: absolute;
            z-index: -1;
            content: '';
            right: calc(50% - 10px);
            top: -8px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent var(--grey-200) transparent;
            transition-duration: 0.3s;
            transition-property: transform;
        }

        .popoverMessage {
            max-height: 200px;
            overflow: auto;
        }
        .popupFooter {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn {
            border-radius: 0;
            width: 64px;
        }
    }

    &.show .popoverContent {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(0, -20px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        min-height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
