.userRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--grey-200);
    border-bottom: 1px solid var(--grey-200);

    section {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        min-width: 0;
        span:first-of-type {
            display: flex;
            align-items: center;
        }
        .name {
            margin-left: 8px;
            font-family: var(--default-font-family);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.actions {
            min-width: fit-content;
            display: flex;
            justify-content: flex-end;
        }
        &.nameSection {
            margin-left: 6px;
            font-size: 12px;
            font-family: var(--default-font-family);
        }
    }
}
