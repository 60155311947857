@mixin shake {
    animation: shake 0.5s ease;

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        20% {
            transform: translateX(-5px);
        }
        40% {
            transform: translateX(5px);
        }
        60% {
            transform: translateX(-5px);
        }
        80% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }
}

.assignmentsMain {
    width: 100%;
    justify-content: center;
    gap: 2rem;
    padding: 0;
    .errorContainer {
        width: 100%;
        margin: 0;
        padding: 4px 12px;
    }
    .assignmentsContainer {
        padding: 20px;
        gap: 2rem;
    }
}

.container {
    align-items: center;
}

.error {
    border: 1px solid var(--red-300);
    legend {
        color: var(--red-300);
    }
    * {
        color: var(--red-300);
    }
}
.loading {
    display: flex;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-radius: 8px;
    min-width: 300px;
    max-width: 300px;
    .header {
        padding: 10px 0 0 4px;
    }
    .body {
        display: flex;
        flex-direction: column;
        min-height: 250px;
        max-height: 250px;
        flex-wrap: nowrap;
        overflow-y: auto;
        &.hideBody {
            display: none;
        }
    }

    .hideFooter {
        display: none;
    }

    &.warehouse {
        box-shadow: 0px 20px 10px -20px var(--red-200) inset;
    }
}
.entityError {
    border: 2px solid var(--red-300);
    @include shake;
}
.error {
    color: var(--red-300);
}

@media only screen and (max-width: 600px) {
    .assignmentsContainer {
        padding: 0;
        margin: 0;
        gap: 0;
    }
    .assignments {
        height: auto;
        padding: 6px;
    }
}
