.qmpApplicationPage {
    padding-left: 2rem;
    font-weight: 500;
}
.selectContainer {
    width: 50%;
    margin-left: 24px;
    z-index: 2;
    .selectField {
        width: 100%;
        max-width: inherit;
    }
}

@media (max-width: 800px) {
    .qmpApplicationPage {
        padding-left: 0;
    }
    .selectContainer {
        width: 97%;
        margin: 10px;
        .selectField {
            width: 100%;
            max-width: inherit;
        }
    }
}
