.header {
    .info {
        justify-content: space-between;
        align-items: center;
        .entityIcon {
            margin-right: 3px;
        }
        .headerName {
            display: flex;
            align-items: center;
            width: 80%;
        }
    }
}
